require('jquery');
require('venobox');
var pageOffset = 100;

var donate_url = 'https://donorbox.org/embed/support-pcf?show_content=false&hide_donation_meter=true';

function resolveHash() {
  window.console.log('HASH:', window.location.hash.substring(1));

  var hash = '#' + window.location.hash.substring(1);
  window.console.log(hash);

  if ($(hash).hasClass('tab-pane') === true) {
    $('a[href*="' + hash + '"]').tab('show');
    // TODO - This should be modified to be more exact. Currently not in use though
    $('html, body').animate({ scrollTop: $(hash).offset().top - pageOffset + 80 }, 1000);
  }
  else if ($(hash).hasClass('modal') === true) {
    $(hash).modal('show');
  }
  else {
    $('html, body').animate({ scrollTop: $(hash).offset().top - pageOffset}, 1000);
  }
  
}

$(document).ready(function() {
  //Instantiate venobox
  $('.venobox').venobox(); 

  // Select all links with hashes
  $('a[href*="#"]')
  //Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .not('[data-toggle="tab"]')    
  .click(function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && 
      location.hostname === this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        //event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top - 50
        }, 1000, function() {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(':focus')) { // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          }
        });
      }
    }
  });


  //check for hash on page load
  if (window.location.hash) { 
    resolveHash();
  }


  $('#donate').on('show.bs.modal', function (e) {
    $(this).find('iframe').attr('src', donate_url);
  });

});
